define("discourse/plugins/discourse-topic-custom-fields/discourse/components/submit-btn", ["exports", "@ember/component", "@ember/service", "discourse/plugins/discourse-topic-custom-fields/discourse/components/modal/custom-model", "@ember/template-factory"], function (_exports, _component, _service, _customModel, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.currentUser}}
  <DButton onclick={{action "handleSubmitTopic" }} @translatedLabel="Submit" />
  {{/if}}
  */
  {
    "id": "bcW39GKK",
    "block": "[[[41,[30,0,[\"currentUser\"]],[[[8,[39,1],[[16,\"onclick\",[28,[37,2],[[30,0],\"handleSubmitTopic\"],null]]],[[\"@translatedLabel\"],[\"Submit\"]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-topic-custom-fields/discourse/components/submit-btn.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    currentUser: (0, _service.inject)(),
    composer: (0, _service.inject)(),
    modal: (0, _service.inject)(),
    init() {
      this._super(...arguments);
    },
    actions: {
      handleSubmitTopic() {
        this.modal.show(_customModel.default);
      }
    }
  }));
});