define("discourse/plugins/discourse-topic-custom-fields/discourse/components/modal/custom-model", ["exports", "@ember/component", "@ember/service", "discourse/models/composer", "discourse/plugins/discourse-topic-custom-fields/discourse/utlis/uploadImage", "@ember/object", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _service, _composer, _uploadImage, _object, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal @title="Submit Topic" @closeModal={{@closeModal}}>
      <:body>
          <label>News:</label>
          <Input @type="text" class="custom-submit-input" onchange={{action "handleOnChange" value="target.value" }}
              onblur={{action "handleOnBlur" value="target.value" }}
              placeholder="http://paste link here to begin submit..." />
          {{#if this.submitError}}
          <span class="text-danger">{{this.submitError}}</span>
          {{/if}}
      </:body>
      <:footer>
          <DButton class="btn-primary" @disabled={{this.disableBtn}} {{action "submit" }} @translatedLabel={{(if
              this.isLoading "Loading..." "Submit" )}} />
          <DButton @action={{@closeModal}} @translatedLabel="Cancel" />
      </:footer>
  </DModal>
  */
  {
    "id": "/mIqlFBT",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@closeModal\"],[\"Submit Topic\",[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,\"label\"],[12],[1,\"News:\"],[13],[1,\"\\n        \"],[8,[39,1],[[24,0,\"custom-submit-input\"],[16,\"onchange\",[28,[37,2],[[30,0],\"handleOnChange\"],[[\"value\"],[\"target.value\"]]]],[16,\"onblur\",[28,[37,2],[[30,0],\"handleOnBlur\"],[[\"value\"],[\"target.value\"]]]],[24,\"placeholder\",\"http://paste link here to begin submit...\"]],[[\"@type\"],[\"text\"]],null],[1,\"\\n\"],[41,[30,0,[\"submitError\"]],[[[1,\"        \"],[10,1],[14,0,\"text-danger\"],[12],[1,[30,0,[\"submitError\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]],[[[1,\"\\n        \"],[8,[39,4],[[24,0,\"btn-primary\"],[4,[38,2],[[30,0],\"submit\"],null]],[[\"@disabled\",\"@translatedLabel\"],[[30,0,[\"disableBtn\"]],[52,[30,0,[\"isLoading\"]],\"Loading...\",\"Submit\"]]],null],[1,\"\\n        \"],[8,[39,4],null,[[\"@action\",\"@translatedLabel\"],[[30,1],\"Cancel\"]],null],[1,\"\\n    \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"input\",\"action\",\"if\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-topic-custom-fields/discourse/components/modal/custom-model.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    url: (0, _tracking.tracked)(),
    submitError: (0, _tracking.tracked)(),
    isLoading: (0, _tracking.tracked)(),
    disableBtn: (0, _object.computed)('url', 'submitError', 'isLoading', function () {
      return !this.url || this.url && this.submitError || this.isLoading;
    }),
    modal: (0, _service.inject)(),
    composer: (0, _service.inject)(),
    actions: {
      async submit() {
        try {
          const targetUrl = this.get('url').trim();
          this.isLoading = true;
          if (targetUrl && (await validateUrl(targetUrl))) {
            let ogData;
            const response = await fetch(`https://corsproxy.io/?https://scrape.n4g.com/fetchMetadata?url=${targetUrl}`); // Todo: Remove Proxy

            if (!response.ok) {
              throw new Error('Error while fetching data');
            }
            ogData = (await response.json())?.data;
            if (ogData) {
              let imageData;
              if (ogData.image && ogData.decodedImage) {
                let imageName = ogData.image.match(/.*\/(.*)$/)[1];
                if (imageName.includes('?')) {
                  imageName = imageName.split('?')[0];
                }
                try {
                  imageData = await createFile(ogData.decodedImage, imageName);
                } catch (error) {
                  throw new Error('Something went wrong! Please try again later');
                }
              }
              let options = {
                title: ogData.title ? ogData.title : '',
                topicBody: ogData.description ? ogData.description : '',
                read_full_story: ogData.url,
                topic_video_input: ogData['video:url'] ? ogData['video:url'] : ''
              };
              if (ogData.image && ogData.decodedImage && imageData) {
                try {
                  const uploadedImage = await (0, _uploadImage.uploadImage)(imageData);
                  options['topic_file_upload'] = uploadedImage.url ? uploadedImage.url : '';
                  options['topic_file_upload_id'] = uploadedImage.id ? uploadedImage.id : null;
                } catch (error) {
                  throw new Error('Something went wrong! Please try again later');
                }
              }
              this.modal.close();
              this.composer.open({
                action: _composer.default.CREATE_TOPIC,
                draftKey: _composer.default.DRAFT,
                ...options
              });
            } else {
              this.modal.close();
            }
          } else {
            throw new Error('Invalid submit url');
          }
        } catch (error) {
          this.set('submitError', error.message);
        } finally {
          this.isLoading = false;
        }
      },
      handleOnChange(value) {
        if (value) {
          this.url = value.trim();
        } else {
          this.url = value;
          this.set('submitError', 'Invalid submit url');
        }
      },
      async handleOnBlur(url) {
        if (url && (await validateUrl(url))) {
          this.set('submitError', null);
        } else {
          this.set('submitError', 'Invalid submit url');
        }
      }
    }
  }));
  async function validateUrl(url) {
    var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    var regex = new RegExp(expression);
    if (url && url.match(regex)) {
      return true;
    } else {
      return false;
    }
  }
  async function createFile(fileURI, imageName) {
    let arr = fileURI.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], imageName, {
      type: mime
    });
  }
});