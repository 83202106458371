define("discourse/plugins/discourse-topic-custom-fields/discourse/templates/connectors/before-create-topic-button/custom-dropdown", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{component "submit-btn"}}
  {{component "custom-dropdown"}}
  */
  {
    "id": "3ULD/qGR",
    "block": "[[[46,\"submit-btn\",null,null,null],[1,\"\\n\"],[46,\"custom-dropdown\",null,null,null]],[],false,[\"component\"]]",
    "moduleName": "discourse/plugins/discourse-topic-custom-fields/discourse/templates/connectors/before-create-topic-button/custom-dropdown.hbs",
    "isStrictMode": false
  });
});