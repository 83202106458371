define("discourse/plugins/discourse-topic-custom-fields/discourse/utlis/helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.slugify = void 0;
  const slugify = str => {
    if (str && typeof str === 'string') {
      return str.toString().toLowerCase().replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
    } else {
      return false;
    }
  };
  _exports.slugify = slugify;
});