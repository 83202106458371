define("discourse/plugins/discourse-topic-custom-fields/discourse/components/custom-dropdown", ["exports", "@ember/component", "@ember/service", "discourse/lib/ajax", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _component, _service, _ajax, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.currentUser}}
  <div class="custom-dropdown">
      <select id="dropdown" onchange={{action "saveSelection" value="target.value" }}>
          <option value="">Select...</option>
          <option value="grid" selected={{eq customField "grid" }}>Grid</option>
          <option value="list" selected={{eq customField "list" }}>List</option>
      </select>
  </div>
  {{/if}}
  */
  {
    "id": "Eam7PH3J",
    "block": "[[[41,[30,0,[\"currentUser\"]],[[[10,0],[14,0,\"custom-dropdown\"],[12],[1,\"\\n    \"],[10,\"select\"],[14,1,\"dropdown\"],[15,\"onchange\",[28,[37,1],[[30,0],\"saveSelection\"],[[\"value\"],[\"target.value\"]]]],[12],[1,\"\\n        \"],[10,\"option\"],[14,2,\"\"],[12],[1,\"Select...\"],[13],[1,\"\\n        \"],[10,\"option\"],[14,2,\"grid\"],[15,\"selected\",[28,[37,2],[[30,0,[\"customField\"]],\"grid\"],null]],[12],[1,\"Grid\"],[13],[1,\"\\n        \"],[10,\"option\"],[14,2,\"list\"],[15,\"selected\",[28,[37,2],[[30,0,[\"customField\"]],\"list\"],null]],[12],[1,\"List\"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `customField` property path was used in the `discourse/plugins/discourse-topic-custom-fields/discourse/components/custom-dropdown.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.customField}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `customField` property path was used in the `discourse/plugins/discourse-topic-custom-fields/discourse/components/custom-dropdown.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.customField}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"action\",\"eq\"]]",
    "moduleName": "discourse/plugins/discourse-topic-custom-fields/discourse/components/custom-dropdown.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    currentUser: (0, _service.inject)(),
    router: (0, _service.inject)(),
    init() {
      this._super(...arguments);
      if (this.currentUser) {
        this.set('customField', this.currentUser.custom_thumbnail_style_dropdown);
      }
    },
    actions: {
      saveSelection(value) {
        let userId = this.currentUser.id;
        let url = `/custom-field/update_custom_field/${userId}`;
        let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        if (!csrfToken) {
          console.error("CSRF token not found");
          return;
        }
        let data = {
          custom_thumbnail_style_dropdown: value
        };
        (0, _ajax.ajax)(url, {
          method: 'POST',
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify(data),
          headers: {
            'X-CSRF-Token': csrfToken
          }
        }).then(() => {
          this.set('currentUser.custom_thumbnail_style_dropdown', value);
          this.router.refresh();
        }).catch(error => {
          console.error('Error:', error);
        });
      }
    }
  }));
});